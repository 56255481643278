import karibuBonus from "../../../assets/img/banner/products/Welcome-Bonus.png";
import MshipiBonusImg from "../../../assets/img/banner/products/Mshipi-Bonus.png";
import hundredPercentDepositBonus from "../../../assets/img/banner/products/Deposit-Bonus.png";
import earlyBirdDailyDepositBonus from "../../../assets/img/banner/products/Deposit-Bonus.png";

export const promoData = [
  {
    title: "First Deposit Bonus",
    image: karibuBonus,
    description: `
      Karibu to the exciting Surebet Deposit Refund Promotion! Receive bonuses on your first deposit of the day to boost your chances of winning!
    `,
    details: `
      - Deposit KES 49: Receive KES 4 as a bonus.
      - Deposit KES 50-98: Receive KES 6 as a bonus.
      - Deposit KES 99: Receive KES 8 as a bonus, up to twice a day.
      - Deposit KES 100 or more: Receive KES 12 as a bonus once a day.
    `,
    termsAndConditions: `
      - The bonus can only be used on bets with minimum odds of 4.99.
      - Only applicable to sportsbook events.
      - Winnings from the bonus will be added to your Surebet wallet.
      - Bonus availability is not guaranteed on all devices.
    `
  },
  {
    title: "Multibet Refund Bonus",
    image: hundredPercentDepositBonus,
    description: `
      Get more chances to win with the Multibet Refund Bonus. Place multi-bets and get a refund if one match loses!
    `,
    details: `
      - 3 Games, 1 Loss: Get 20% back.
      - 8-20 Games, 1 Loss: Get 100% back.
      - More Than 20 Games, 1 Loss: Get 300% back.
      - More Than 15 Games, 2 Losses: Get 50% back.
    `,
    termsAndConditions: `
      - Maximum refund per bet is Ksh. 5,000.
      - Free bets are non-withdrawable and expire in 48 hours.
      - Free bets cannot be used in conjunction with other promotions.
    `
  },
  {
    title: "Bet bila Bundles",
    image: MshipiBonusImg,
    description: `
      Access Surebet’s website for free with Safaricom data bundles. This offer only applies when using Safaricom mobile network and excludes live streaming.
    `,
    details: `
      - Only available for Safaricom users.
      - Does not apply to Safaricom Home Fibre or any charges for other Surebet services.
      - Misuse of the promotion may lead to suspension or blocking of the service.
    `,
    termsAndConditions: `
      - Surebet reserves the right to alter or discontinue the offer.
      - The service is provided “as is” without warranties of any kind.
      - Additional terms apply from Surebet's Privacy Policy.
    `
  },
  {
    title: "Form Fridays",
    image: earlyBirdDailyDepositBonus,
    description: `
      Get a 100% reload bonus on Fridays for sports betting. Deposit at least 150 KES and claim your bonus up to 15,000 KES!
    `,
    details: `
      - Make a qualifying deposit on Friday to claim your bonus.
      - The bonus must be wagered 3x in accumulator bets within 24 hours.
      - Each accumulator must have at least 3 selections with odds of 1.49 or more.
    `,
    termsAndConditions: `
      - Only one bonus per customer per week.
      - Bonus will be canceled if not wagered within 24 hours.
      - The bonus is only valid for sports events.
    `
  },
  {
    title: "Money Back",
    image: karibuBonus,
    description: `
      Get up to 100x your stake back on multi-bets if one match loses. The higher your odds, the greater your potential refund!
    `,
    details: `
      - 1x Cash Back: Winning odds 24+.
      - 2x Cash Back: Winning odds 104+.
      - 10x Cash Back: Winning odds 304+.
      - 20x Cash Back: Winning odds 804+.
      - 50x Cash Back: Winning odds 2004+.
    `,
    termsAndConditions: `
      - Valid only for multi-bets with at least 1 match from the pre-match offer.
      - The promotion does not apply to single bets.
      - Online customers only.
    `
  },
  {
    title: "Quiz Bet",
    image: hundredPercentDepositBonus,
    description: `
      Build your bet by answering questions in the Quiz Bet feature. Skip questions, choose your stake, and check your potential win before placing the bet.
    `,
    details: `
      - Answer questions and each answer becomes part of your bet.
      - The bet outcome is based on regular match time and injury time.
      - Overtime or penalty shootouts do not affect the outcome unless otherwise stated.
    `,
    termsAndConditions: `
      - Valid only for football markets.
      - Terms and conditions apply.
    `
  },
  {
    title: "Deposit Promo",
    image: MshipiBonusImg,
    description: `
      Claim a 20% bonus on your 1st daily deposit. 
      Maximum bonus: KES 1500. 
      Play tickets or slot offers with the bonus.
    `,
    details: `
      - Minimum deposit: KES 30.
      - Maximum bonus: KES 1500.
      - Valid only for first daily deposit.
      - Excludes Turbo Cash, System, and bonus money tickets.
      - Bonus paid by 11am the next day.
    `,
    termsAndConditions: `
      - One active bonus per account.
      - The bonus must be used before making another deposit.
      - Organizers reserve the right to exclude users violating rules.
    `
  },
  {
    title: "Monday Blues Promotion",
    image: earlyBirdDailyDepositBonus,
    description: `
      Get a 100% bonus on deposits up to KES 10,000. 
      Minimum deposit: KES 175. 
      Wager 3x the bonus on accumulator bets within 24 hours.
    `,
    details: `
      - Bonus available once per user.
      - Wager 3x on accumulator bets with 3+ events and odds 1.49+.
      - Bonus must be used within 24 hours.
    `,
    termsAndConditions: `
      - Only active customers eligible.
      - Cannot be combined with other promotions.
      - Surebet can amend or cancel offer at any time.
    `
  },
  {
    title: "Bet Share",
    image: karibuBonus,
    description: `
      Share your bet with friends and win up to KES 15,000. 
      Place a bet on pre-match games with odds 5.99+.
    `,
    details: `
      - Minimum stake: KES 20.
      - Sharebet prize pool includes multiple winners.
      - Only applies to pre-match bets.
    `,
    termsAndConditions: `
      - Voided/canceled bets do not count.
      - Maximum 1 bet per share.
      - The more you bet, the higher your chances of winning.
    `
  },
  {
    title: "Top of the Hour Cash",
    image: earlyBirdDailyDepositBonus,
    description: `
      Last bet of the hour wins a free bet worth the net stake. 
      Valid for pre-match bets only.
    `,
    details: `
      - Minimum stake: KES 50.
      - Winning stake credited as bonus, valid for 7 days.
      - Applies to pre-match bets only.
    `,
    termsAndConditions: `
      - Final decision rests with Surebet management.
      - Bonus valid for 7 days.
    `
  }  
];